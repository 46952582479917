import { api } from '~/utils/api';
import { useState } from 'react';
import useMobile from './useMobile';
import patientPortalStore from '~/store/patientPortal';
import useSafePush from './useSafePush';
import { useRouter } from 'next/router';

const useBankId = ({
	environment,
	callback,
}: {
	environment?: string | undefined;
	callback?: string;
}) => {
	const device = useMobile();
	const [mode, setMode] = useState<'qr' | 'autostart' | undefined>(undefined);
	const store = patientPortalStore();
	const { safePush } = useSafePush();
	const router = useRouter();

	const authStart = api.auth.bankid.create.useMutation({});
	const authStatus = api.auth.bankid.status.useQuery(
		{
			isQrCode: mode === 'qr' ? true : false,
			environment,
			auth: authStart.data?.authorization,
		},
		{
			enabled: authStart.data?.bankid !== undefined,
			refetchOnWindowFocus: false,
			onSuccess(data) {
				const anchorIndex = window.location.href.indexOf('#');
				if (anchorIndex !== -1) {
					// If an anchor is found, remove it
					const url = router.asPath.substring(0, anchorIndex);
					window.history.pushState(null, '', url);
				}

				if (data.bankid.status === 'Complete') {
					//clear anchor on url
					if (data.authorization && data.authorization.accessToken) {
						store.setJWT(data.authorization);
						setTimeout(() => {
							void safePush('/dashboard');
						}, 500);
					}
				} else if (data.bankid.status === 'NotFound') {
					setTimeout(() => {
						void safePush('/notfound');
					}, 500);
				}
			},
			onError(error) {
				if (error.message !== 'UNAUTHORIZED') {
					console.error('API error: ', JSON.stringify(error));
					setMode(undefined);
					authStart.reset();
				}
			},
			refetchInterval(data) {
				if (data !== undefined && data.bankid.status === 'Pending') {
					return 1000;
				}
				if (data !== undefined && data.bankid.status === 'Failed') {
					setMode(undefined);
					authStart.reset();
					return false;
				}

				return false;
			},
		}
	);

	const authCancel = api.auth.bankid.cancel.useMutation({});

	return {
		start: () => {
			if (authStart.isLoading) return;
			//Add a unique id to the location
			authStart.mutate({ environment, country: 'SE' });
		},
		startData: authStart,
		status: authStatus,
		mode,
		cancel: () => {
			if (authCancel.isLoading) return;
			setMode(undefined);
			if (!authStart.data) return;
			authStart.reset();
			if (authStatus.data?.bankid.status === 'Failed') return;
			authCancel.mutate({ auth: authStart.data.authorization, environment });
		},
		startWithAutoStart: () => {
			if (authStart.isLoading) return;
			setMode('autostart');
		},
		startWithQr: () => {
			if (authStart.isLoading) return;
			setMode('qr');
		},
		autoStartUrl: () => {
			if (!authStart.data?.bankid.autoStartToken) return;

			if (device.isMobile) {
				if (device.isIphone && device.isIphoneCriOS) {
					return `https://app.bankid.com/?autostarttoken=${authStart.data?.bankid.autoStartToken}&redirect=googlechrome://`;
				} else if (device.isIphone && device.isSafari) {
					// HERE we can add redirect to ourself with appended #bankIdRedirect
					let redirect = 'null';
					if (callback && callback !== 'null') {
						redirect = `${callback}/#bankIdRedirect`;
					}
					// WARN Removed the redirect url in this case since iOS is still getting issues
					return new URL(
						`https://app.bankid.com/?autostarttoken=${authStart.data?.bankid.autoStartToken}&redirect=null`
					).toString();
				} else if (
					device.isSamsungBrowser ||
					device.isXiaomiBrowser ||
					device.isEdgeOnAndroid ||
					device.isHuaweiBrowser ||
					device.isFirefoxOnAndroid ||
					device.isOperaOnAndroid
				) {
					return `bankid:///?autostarttoken=${authStart.data.bankid.autoStartToken}&redirect=null`;
				} else {
					// Default case for mobile
					return `https://app.bankid.com/?autostarttoken=${authStart.data?.bankid.autoStartToken}&redirect=null`;
				}
			} else {
				// DESKTOP
				return `bankid:///?autostarttoken=${authStart.data.bankid.autoStartToken}&redirect=null`;
			}
		},
	};
};

export default useBankId;
