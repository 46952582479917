import useTranslation from '~/hooks/useTranslation';
import type { Country, Locale } from '~/utils/marketResolver';
import { api } from '~/utils/api';
import { useRouter } from 'next/router';
import patientPortalStore from '~/store/patientPortal';
import ButtonLink from '~/components/ButtonLink';
import Button from '~/components/Button';
import { useState, useEffect } from 'react';
import ProgressLoader from '~/components/ProgressLoader';
import useSafePush from '~/hooks/useSafePush';

type Props = {
	locale: Locale;
	country: Country;
};

const Signicat = ({ locale, country }: Props) => {
	const { t } = useTranslation({ locale, domains: ['auth'] });
	const router = useRouter();
	const store = patientPortalStore();
	const [signicatStatus, setSignicatStatus] = useState<
		'success' | 'cancel' | 'error' | undefined
	>(undefined);
	const { safePush } = useSafePush();

	const start = api.auth.signicat.create.useMutation({
		onSuccess: (data) => {
			if (data?.authorization) {
				store.setLoginJWT(data.authorization);
				window.open(data.signicat.authenticationUrl, '_self');
			}
		},
	});

	useEffect(() => {
		if (router.isReady) {
			const status = router.query?.signicatStatus?.toString();

			if (status) {
				switch (status) {
					case 'success':
						setSignicatStatus('success');
						break;
					case 'abort':
						setSignicatStatus('cancel');
						break;
					case 'error':
						setSignicatStatus('error');
						break;
				}
			}
		}
	}, [router]);

	api.auth.signicat.status.useQuery(
		{
			auth: store.loginAuth,
		},
		{
			enabled: Boolean(signicatStatus === 'success') && store.loginAuth !== undefined,
			onSuccess: (data) => {
				if (data.status === 'NotFound') {
					store.setLoginJWT(undefined);
					setTimeout(() => {
						void safePush('/notfound');
					}, 500);
				} else if (data.status === 'Success' && data?.authorization) {
					store.setJWT(data.authorization);
					setTimeout(() => {
						void safePush('/dashboard');
					}, 500);
				}
			},
		}
	);

	return (
		<div className="flex flex-col items-center justify-center">
			<div className="mb-10 flex h-[100px] w-auto flex-col items-center justify-center gap-4 text-center">
				{signicatStatus === undefined && !start.data && (
					<Button
						onClick={(e) => {
							e.preventDefault();
							if (signicatStatus === undefined) {
								start.mutate({ country: country });
							}
						}}
					>
						<span>{t('start')}</span>
					</Button>
				)}
				{signicatStatus === undefined && start.data && (
					<>
						<ProgressLoader />
						<ButtonLink href={start.data.signicat.authenticationUrl}>
							<span>{t('open_provider')}</span>
						</ButtonLink>
					</>
				)}
				{signicatStatus === 'success' && (
					<>
						<ProgressLoader />
						<span>{t('status_login')}</span>
					</>
				)}
				{signicatStatus === 'cancel' && (
					<>
						<Button
							onClick={(e) => {
								e.preventDefault();
								setSignicatStatus(undefined);
							}}
						>
							<span>{t('retry')}</span>
						</Button>
					</>
				)}
			</div>
		</div>
	);
};

export default Signicat;
