import type { NextPage, GetServerSideProps } from 'next';
import Image from 'next/image';
import AuthProvider from '../components/AuthProvider';
import Header from '../components/Header';
import Layout from '../components/Layout';
import FadeIn from '../components/Transitions/FadeIn';
import { useRouter } from 'next/router';
import useTranslation from '~/hooks/useTranslation';
import { type Country, type Locale, getCountry, getLocale } from '~/utils/marketResolver';
import patientPortalStore from '~/store/patientPortal';
import { useEffect } from 'react';
import { env } from '~/env.mjs';

const logos: { [key in Country]: { src: string; alt: string } } = {
	SE: {
		src: '/kollarna_logo.svg',
		alt: 'Kollarna.se logga',
	},
	FI: {
		src: '/logos/uti_logo_fi.svg',
		alt: 'Virtsaapu.fi',
	},
};

const Start: NextPage<{ country: Country; locale: Locale; callback: string | undefined }> = ({
	country,
	locale,
	callback,
}) => {
	const router = useRouter();
	const { t } = useTranslation({ locale, domains: ['page'] });
	const store = patientPortalStore();

	useEffect(() => {
		if (store.country !== country) store.setMarket({ country, locale });
	}, [store, country, locale]);

	return (
		<>
			<Header locale={locale} />
			<Layout>
				<FadeIn show={true}>
					<div className="flex flex-col items-center justify-center gap-4">
						<Image
							src={logos[country].src}
							alt={logos[country].alt}
							width={2682}
							height={711}
							priority={true}
							className="h-auto w-[300px]"
						/>
						<h1 className="text-center text-3xl">{t('portal_title')}</h1>
						<AuthProvider
							callback={callback}
							locale={locale}
							country={country}
							environment={
								router.isReady ? router.query?.environment?.toString() : undefined
							}
						/>
					</div>
				</FadeIn>
			</Layout>
		</>
	);
};

export const getServerSideProps: GetServerSideProps<{
	country: Country;
	locale: string;
	callback: string;
}> = async (context) => {
	const country: Country = await new Promise((resolve) => {
		resolve(getCountry(context.req.headers.host));
	});

	return {
		props: {
			country,
			locale: getLocale(context.locale),
			callback: env.HOST ?? 'null',
		},
	};
};

export default Start;
