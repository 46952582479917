const useMobile = (): {
	isMobile: boolean;
	isIphone: boolean;
	isAndroid: boolean;
	isSafari: boolean;
	isIphoneCriOS: boolean;
	isSamsungBrowser: boolean;
	isEdgeOnAndroid: boolean;
	isXiaomiBrowser: boolean;
	userAgent: string;
	isHuaweiBrowser: boolean;
	isFirefoxOnAndroid: boolean;
	isOperaOnAndroid: boolean;
} => {
	// figure out user agent if we are on a mobile device
	const userAgent = navigator.userAgent;
	const isMobile = Boolean(userAgent.match(/Android|Mobile|iPhone|iPad|iPod|Opera Mini/i));
	const isAndroid = Boolean(userAgent.match(/Android/i));
	const isIphone = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
	const isSafari = Boolean(userAgent.match(/Safari/i));
	const isIphoneCriOS = Boolean(userAgent.match(/CriOS/i));

	// if we are on a samsung browser
	const isSamsungBrowser = Boolean(userAgent.match(/SamsungBrowser/i));
	const isEdgeOnAndroid = Boolean(userAgent.match(/EdgA/i));
	const isXiaomiBrowser = Boolean(userAgent.match(/MiuiBrowser/i));
	const isHuaweiBrowser = Boolean(userAgent.match(/HuaweiBrowser/i));
	const isFirefoxOnAndroid =
		Boolean(userAgent.match(/Android/i)) && Boolean(userAgent.match(/Firefox/i));
	const isOperaOnAndroid =
		Boolean(userAgent.match(/Android/i)) && Boolean(userAgent.match(/OPR/i));

	return {
		isMobile,
		isIphone,
		isAndroid,
		isSafari,
		isIphoneCriOS,
		userAgent,
		isSamsungBrowser,
		isEdgeOnAndroid,
		isXiaomiBrowser,
		isHuaweiBrowser,
		isFirefoxOnAndroid,
		isOperaOnAndroid,
	};
};

export default useMobile;
