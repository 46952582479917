import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

const useSafePush = () => {
	const router = useRouter();
	const [onChanging, setOnChanging] = useState(false);

	const handleRouteChange = useCallback(() => {
		setOnChanging(false);
	}, []);

	// safePush is used to avoid route pushing errors when users click
	// multiple times or when the network is slow:  "Error: Abort fetching component for route"
	const safePush = useCallback(
		(path: string) => {
			if (!onChanging) {
				setOnChanging(true);
				void router.push(path);
			}
		},
		[onChanging, router]
	);

	useEffect(() => {
		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router, setOnChanging, handleRouteChange]);
	return { safePush };
};

export default useSafePush;
