import Image from 'next/image';
import { type Country } from '~/utils/marketResolver';

const diagnosis_sites: { [key: string]: { image: string; name: string; url: string }[] } = {
	SE: [
		{
			image: '/logos/urinkollen_logo_sv.png',
			name: 'Urinkollen',
			url: 'https://www.urinkollen.se/',
		},
		{
			image: '/logos/aknekollen_logo_sv.png',
			name: 'Aknekollen',
			url: 'https://www.aknekollen.se/',
		},
		{
			image: '/logos/menskollen_logo_sv.png',
			name: 'Menskollen',
			url: 'https://www.menskollen.se/',
		},
		{
			image: '/logos/herpeskollen_logo_sv.png',
			name: 'Herpeskollen',
			url: 'https://www.herpeskollen.se/',
		},
		{
			image: '/logos/ogonkollen_logo_sv.png',
			name: 'Ögonkollen',
			url: 'https://www.ogonkollen.se/',
		},
		{
			image: '/logos/borreliakollen_logo_sv.png',
			name: 'Borreliakollen',
			url: 'https://www.borreliakollen.se/',
		},
		{
			image: '/logos/ppillerkollen_logo_sv.svg',
			name: 'Ppillerkollen',
			url: 'https://www.ppillerkollen.se/',
		},
		{
			image: '/logos/pollenkollen_logo_sv.svg',
			name: 'Pollenkollen',
			url: 'https://www.pollenkollen.se/',
		},
	],
	FI: [],
};

const Promotion = ({ country = 'SE' }: { country: Country }) => {
	return (
		<div className="mt-4 flex w-5/6 flex-wrap items-center justify-center">
			{diagnosis_sites[country]?.map((site) => {
				return (
					<a key={site.url} href={site.url} target="_blank" rel="noopener noreferrer">
						<Image
							src={site.image}
							alt={site.name}
							width={150}
							height={50}
							priority={true}
							className="h-auto p-2"
						/>
					</a>
				);
			})}
		</div>
	);
};

export default Promotion;
