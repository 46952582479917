import { type Locale } from '~/utils/marketResolver';

const translations = {
	sv_SE: {
		'auth:open_provider': 'Öppna med FTN inlogg',
		'auth:retry': 'Försök igen',
		'auth:start': 'Logga in',
		'auth:status_login': 'Loggar in...',
		'common:assessment_available':
			'Du har fått en läkarbedömning för ditt senaste besök hos {{checkup}}',
		'common:back': 'Tillbaka',
		'common:change': 'Ändra',
		'common:close': 'Stäng',
		'common:contact_email': 'doktor@kollarna.se',
		'common:conversation_closed': 'Denna konversation är stängd',
		'common:conversation_title': 'Meddelanden',
		'common:conversation_title_menu': 'Mina meddelanden',
		'common:download_pdf': 'Ladda ner',
		'common:drug': 'Läkemedel',
		'common:email': 'Email',
		'common:error_description_messages': 'Kvarstår felet kontakta supporten via mail {{email}}',
		'common:error_message_too_long': 'Meddelandet är för långt',
		'common:error_title_default': 'Något gick fel',
		'common:error_title_messages': 'Kunde inte ladda meddelanden',
		'common:error_visit_message': 'Kunde inte hämta besök',
		'common:hello': 'Hej {{name}}',
		'common:list_visits': 'Se dina tidigare besök här',
		'common:logout': 'Logga ut',
		'common:messages_icon_title': 'Meddelande',
		'common:multiple_user_notification':
			'Din inloggning har flera användare, vill du byta användare?',
		'common:no_messages': 'Inga meddelanden',
		'common:no_pending_visits': 'Du har inga pågående ärenden hos oss!',
		'common:no_visits': 'Inga besök!',
		'common:no_visits_pending_for_user': 'Inga pågående ärenden för {{name}}',
		'common:pending': 'Pågående',
		'common:phone': 'Telefon',
		'common:placeholder_cannot_reply': 'Går inte att svara på ett stängt besök',
		'common:placeholder_write_message': 'Skriv ditt meddelande här...',
		'common:portal_description': 'Patient Portal för kollarna.se',
		'common:portal_title': 'Kollarna.se - Patient Portal',
		'common:questions_contact': 'Vid frågor kontakta oss på ',
		'common:read_viewed': 'Läst',
		'common:read_viewed_messages': 'Lästa meddelanden',
		'common:send': 'Skicka',
		'common:settings_title': 'Inställningar',
		'common:settings_title_menu': 'Inställningar',
		'common:show': 'Visa',
		'common:signed_children': 'Barn du har signerat för',
		'common:start_title': 'Start',
		'common:start_title_menu': 'Start',
		'common:swap_user': 'Byt användare',
		'common:unread': 'Oläst',
		'common:unread_message_notification':
			'Du har ett oläst meddelande för ditt senaste besök hos {{checkup}}',
		'common:unread_messages': 'Olästa meddelanden',
		'common:visit_still_pending': 'Ditt ärende är inte klar än',
		'common:visit_title': 'Besök',
		'common:visit_title_menu': 'Mina besök',
		'page:portal_title': 'Patientportal',
		'page:user_not_found':
			'Du har inte några registrerade besök hos oss. Använd gärna någon av våra tjänster nedan!',
		'reciept:pdf_reader_missing': 'Kan inte ladda kvitto, saknar .pdf läsare.',
		'reciept:print_or_download': 'Ladda ner/skriv ut kvitto',
		'reciept:title': 'Kvitto',
		'visit:lakemedelskollen_child_info':
			'För att använda Läkemedelskollen behöver du ha ett BankID för att logga in.\nOm du är 13 år eller äldre kan du inte använda din förälders BankID för att logga in.',
		'visit:show_chat': 'Visa meddelanden',
		'visit:show_unread_chat': 'Visa olästa meddelanden',
		'payment:information_visit_end': 'Ditt ärende är nu slutfört. Vänligen betala nedanför.',
		'payment:information_start':
			'Vården tillhandahålls i enlighet med Region Sörmlands regelverk.',
		'payment:information_payment_select':
			'Vården tillhandahålls i enlighet med Region Sörmlands regelverk (återbesök 100kr).',
		'payment:terms': 'Genom att välja betalmetod godkänner jag köpvillkoren',
		'payment:start': 'Starta',
		'payment:pay': 'Betala',
		'payment:cancel': 'Avbryt',
		'payment:information_payment_paid': 'Tack. Allt är nu klart!',
		'payment:information_payment_refunded': 'Besöket är återbetald.',
		'payment:mobile': 'Mobilnummer',
		'payment:swish_pending': 'Vänligen starta Swish i din mobila enhet med nummer {{mobile}}',
		'upload:upload_limit_error':
			'Du har nått max antal bilder som är möjligt att ladda upp i denna konversation.',
		'upload:upload_empty_error': 'Något är fel med bilden som du försöker ladda upp',
	},
	fi_FI: {
		'auth:open_provider': 'Eikö linkki aukea? Paina tästä!',
		'auth:retry': 'Yritä uudelleen',
		'auth:start': 'Kirjaudu sisään',
		'auth:status_login': 'Kirjautuu sisään...',
		'common:assessment_available':
			'Sinulle on tehty lääkärin arvio viimeisimmästä käynnistäsi {{checkup}}',
		'common:back': 'Takaisin',
		'common:change': 'Muuta',
		'common:close': 'Sulje',
		'common:contact_email': 'tohtori@virtsaapu.fi',
		'common:conversation_closed': 'Tämä keskustelu on suljetttu',
		'common:conversation_title': 'Viestit',
		'common:conversation_title_menu': 'Omat viestini',
		'common:download_pdf': 'Lataa',
		'common:drug': 'Lääke',
		'common:email': 'Sähköposti',
		'common:error_description_messages':
			'Jos virhe jatkuu, ota yhteyttä tukeen sähköpostitse {{email}}',
		'common:error_message_too_long': 'Viesti on liian pitkä',
		'common:error_title_default': 'Jotain meni pieleen',
		'common:error_title_messages': 'Viestejä ei voitu ladata',
		'common:error_visit_message': 'Käyntejä ei voitu noutaa',
		'common:hello': 'Hei {{name}}',
		'common:list_visits': 'Tarkastele aikaisempia käyntejäsi',
		'common:logout': 'Kirjaudu ulos',
		'common:messages_icon_title': 'Viesti',
		'common:multiple_user_notification':
			'Kirjautumisessasi on useita käyttäjiä, haluatko vaihtaa käyttäjää?',
		'common:no_messages': 'Ei viestejä',
		'common:no_pending_visits': 'Sinulla ei ole käynnissä olevia asioita kanssamme!',
		'common:no_visits': 'Ei käyntejä!',
		'common:no_visits_pending_for_user': 'Ei kesken olevia asioita käyttäjällä {{name}}',
		'common:pending': 'Kesken',
		'common:phone': 'Puhelin',
		'common:placeholder_cannot_reply': 'Et voi vastata suljettuun käyntiin',
		'common:placeholder_write_message': 'Kirjoita viesti tähän...',
		'common:portal_description': 'Potilasportaali virtsaapu.fi',
		'common:portal_title': 'Virtsaapu.fi - Potilasportaali',
		'common:questions_contact': 'Jos sinulla on kysyttävää, ota yhteyttä meihin osoitteessa',
		'common:read_viewed': 'Luettu',
		'common:read_viewed_messages': 'Luetut viestit',
		'common:send': 'Lähetä',
		'common:settings_title': 'Asetukset',
		'common:settings_title_menu': 'Asetukset',
		'common:show': 'Näytä',
		'common:signed_children': 'Lapset joiden puolesta allekirjoitettu',
		'common:start_title': 'Aloita',
		'common:start_title_menu': 'Aloita',
		'common:swap_user': 'Vaihda käyttäjää',
		'common:unread': 'Lukematon',
		'common:unread_message_notification':
			'Sinulla on lukematon viesti viimeisimmästä käynnistäsi {{checkup}}',
		'common:unread_messages': 'Lukemattomat viestit',
		'common:visit_still_pending': 'Asiasi on vielä kesken',
		'common:visit_title': 'Vieraile',
		'common:visit_title_menu': 'Omat käyntini',
		'page:portal_title': 'Potilasportaali',
		'page:user_not_found':
			'Sinulla ei ole rekisteröityjä käyntejä meillä. Kokeile jotakin alla olevista palveluistamme!',
		'reciept:pdf_reader_missing': 'Kuittia ei voida ladata, .pdf-lukija puuttuu.',
		'reciept:print_or_download': 'Lataa/tulosta kuitti',
		'reciept:title': 'Kuitti',
		'visit:lakemedelskollen_child_info':
			'Läaäkeaineiston tarkistamiseksi tarvitset pankkitunnukset kirjautumiseen. Jos olet 13-vuotias tai vanhempi, et voi käyttää vanhempasi pankkitunnuksia kirjautumiseen.',
		'visit:show_chat': 'Näytä viestit',
		'visit:show_unread_chat': 'Näytä lukemattomat viestit',
		'payment:terms': '',
		'payment:start': '',
		'payment:cancel': '',
		'payment:pay': '',
		'payment:information_start': '',
		'payment:information_visit_end': '',
		'payment:information_payment_select': '',
		'payment:information_payment_paid': '',
		'payment:information_payment_refunded': '',
		'payment:mobile': '',
		'payment:swish_pending': '',
		'upload:upload_limit_error': '',
		'upload:upload_empty_error': '',
	},
} as const;

type TranslationDomains = 'auth' | 'common' | 'page' | 'visit' | 'reciept' | 'payment' | 'upload';

type TranslationKeys<T extends Locale, D extends TranslationDomains> = {
	[K in keyof (typeof translations)[T]]: K extends `${D}:${infer Rest}` ? Rest : never;
}[keyof (typeof translations)[T]];

//TODO - Add support for pluralization - When needed

const useTranslation = <
	T extends Locale = Locale,
	D extends TranslationDomains = TranslationDomains
>({
	locale = 'sv_SE' as T,
	domains = ['common'] as D[],
	default: defaultValue = 'unknown',
}: {
	locale?: T;
	domains?: D[];
	default?: string;
} = {}) => {
	const t = <K extends TranslationKeys<T, D>>(
		key: K,
		replacements?: { [key: string]: string | number }
	) => {
		let translation: string | undefined;

		// Find translation in the specified domains
		for (const domain of domains) {
			const translationValue =
				translations[locale][`${domain}:${key}` as keyof (typeof translations)[T]];
			if (translationValue) {
				translation = translationValue as string;
				break;
			}
		}

		if (!translation) {
			// Translation not found, you can handle this case as needed
			console.warn(`Translation not found for key: ${key}`);
			return defaultValue;
		}

		if (replacements) {
			return Object.entries(replacements).reduce((acc, [key, value]) => {
				if (typeof value === 'number') {
					return acc.replace(`{{${key}}}`, value.toString());
				}
				return acc.replace(`{{${key}}}`, value);
			}, translation);
		}

		return translation;
	};

	return { t };
};

export default useTranslation;
