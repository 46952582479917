import type { NextPage } from 'next';
import Head from 'next/head';
import useTranslation from '~/hooks/useTranslation';
import { type Locale } from '~/utils/marketResolver';

const Header: NextPage<{ locale: Locale }> = ({ locale }) => {
	const { t } = useTranslation({ locale });
	return (
		<Head>
			<title>{t('portal_title')}</title>
			<meta name="description" content={t('portal_description')} />
			<link rel="icon" href="/favicon.ico" />
		</Head>
	);
};

export default Header;
