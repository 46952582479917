import Image from 'next/image';
import React from 'react';
import Button from '~/components/Button';
import Promotion from '~/components/Promotion/Promotion';
import useBankId from '~/hooks/useBankId';
import useMobile from '~/hooks/useMobile';
import QRCode from 'react-qr-code';
import ProgressLoader from '~/components/ProgressLoader';
import ButtonLink from '~/components/ButtonLink';
import { type Country } from '~/utils/marketResolver';

const SignOnOtherDevice = ({ action }: { action: () => void }) => {
	return (
		<Button
			onClick={(e) => {
				e.preventDefault();
				action();
			}}
			title="Öppna BankID på annan enhet"
		/>
	);
};

const SignOnThisDevice = ({ action, href }: { action: () => void; href: string | undefined }) => {
	return (
		<ButtonLink
			href={href}
			onClick={() => {
				action();
			}}
		>
			<span>Öppna BankID på denna enhet</span>
		</ButtonLink>
	);
};
const BankId = ({
	environment,
	country,
	callback,
}: {
	environment?: string;
	country: Country;
	callback?: string;
}) => {
	const { isMobile } = useMobile();
	const bankId = useBankId({ environment, callback });

	return (
		<div className="flex flex-col items-center justify-center">
			<div className="mb-10 flex h-[300px] w-auto flex-col items-center justify-center gap-4 text-center">
				{bankId.startData.data && !bankId.mode && (
					<>
						<Image
							className="h-[200px] w-auto place-self-center"
							priority={true}
							src="/bankid_logo.svg"
							alt="BankID"
							width="619"
							height="513"
						/>
						{!isMobile ? (
							<div className="flex w-[280px] flex-col items-center justify-center gap-2">
								<SignOnOtherDevice action={bankId.startWithQr} />
								<SignOnThisDevice
									action={bankId.startWithAutoStart}
									href={bankId.autoStartUrl()}
								/>
							</div>
						) : (
							<div className="item-center flex w-[280px] flex-col justify-center gap-2">
								<SignOnThisDevice
									action={bankId.startWithAutoStart}
									href={bankId.autoStartUrl()}
								/>
								<SignOnOtherDevice action={bankId.startWithQr} />
							</div>
						)}
					</>
				)}
				{bankId.startData.data && bankId.mode && (
					<div className="mb-4 flex h-[300px] w-auto flex-col items-center justify-center gap-2">
						{bankId.mode === 'qr' && (
							<div className="mt-5 h-[300px] justify-center gap-5 text-center">
								{bankId.status.data?.bankid.qrCode && (
									<QRCode
										size={194}
										level="L"
										bgColor="#f6f2eb"
										value={bankId.status.data.bankid.qrCode}
									/>
								)}
							</div>
						)}
						{bankId.mode === 'autostart' &&
							bankId.status.data?.bankid.status === 'Pending' && (
								<div className="mt-5 grid h-[300px] place-items-center items-center justify-center gap-5 text-center">
									<ProgressLoader />
								</div>
							)}
						{bankId.mode &&
							bankId.status.data?.bankid.userMessage &&
							bankId.status.data?.bankid.status === 'Pending' && (
								<div className="mt-2 w-2/3 break-words text-center text-sm">
									{bankId.status.data.bankid.userMessage}
								</div>
							)}
						<div className="w-[240px]">
							{bankId.status.data?.bankid.status !== 'Complete' &&
								bankId.status.data?.bankid.status !== 'NotFound' && (
									<Button
										title={
											bankId.status.data?.bankid.status === 'Failed'
												? 'Stäng'
												: 'Avbryt'
										}
										onClick={(e) => {
											e.preventDefault();
											bankId.cancel();
										}}
										btnType="cancel"
									/>
								)}
							{bankId.status.data?.bankid.status === 'Complete' ||
								(bankId.status.data?.bankid.status === 'NotFound' && (
									<ProgressLoader />
								))}
						</div>
					</div>
				)}
				{!bankId.startData.data && (
					<>
						<Image
							className="h-[200px] w-auto "
							priority={true}
							src="/bankid_logo.svg"
							alt="BankID"
							width="619"
							height="513"
						/>
						<button
							onClick={(e) => {
								e.preventDefault();
								bankId.start();
							}}
							disabled={bankId.startData.isLoading}
							className="w-full rounded-md bg-sky-500 p-2 text-neutral-50 shadow-sm shadow-slate-200 hover:bg-sky-600 disabled:cursor-not-allowed disabled:text-gray-200"
						>
							{bankId.startData.isLoading && (
								<ProgressLoader size="small" color="#fff" />
							)}
							{!bankId.startData.isLoading && <span>Logga in</span>}
						</button>
					</>
				)}
			</div>
			<Promotion country={country} />
		</div>
	);
};

export default BankId;
