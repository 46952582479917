import type { Country, Locale } from '~/utils/marketResolver';
import BankId from '~/components/AuthProvider/BankId';
import Signicat from '~/components/AuthProvider/Signicat';

type Props = {
	locale: Locale;
	country: Country;
	environment?: string;
	callback?: string;
};

const AuthProvider = ({ callback, locale, country, environment }: Props) => {
	switch (country) {
		case 'SE': {
			return <BankId callback={callback} environment={environment} country={country} />;
		}
		case 'FI': {
			return <Signicat locale={locale} country={country} />;
		}
	}
};

export default AuthProvider;
