import { Transition } from '@headlessui/react';
import React, { useState } from 'react';

type ComponentProps = {
	children: React.ReactNode;
	show?: boolean;
	delay?: number;
};

const FadeIn = ({ show = false, children, delay = 300 }: ComponentProps) => {
	const [showChildren, setShowChildren] = useState(false);

	setTimeout(() => {
		setShowChildren(show);
	}, delay);

	return (
		<>
			<Transition
				show={showChildren}
				appear={true}
				enter="transition-opacity duration-1000"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-1000"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				{children}
			</Transition>
		</>
	);
};

export default FadeIn;
