import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const ButtonLink = (props: Props) => {
	return (
		<a
			{...props}
			className={twMerge(
				`
			    	w-full
			    	rounded-md
			    	bg-sky-500
			    	p-2
			    	text-center
			    	text-neutral-50
			    	shadow-sm
			    	shadow-slate-200
			    	hover:bg-sky-600
				`,
				props.className
			)}
		>
			{props.children}
		</a>
	);
};

export default ButtonLink;
