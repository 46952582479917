import React from 'react';
import type { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonType = 'action' | 'cancel' | 'secondary';

interface ComponentProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	title?: string;
	action?: () => void;
	disabled?: boolean;
	btnType?: ButtonType;
}

const Button: React.FC<ComponentProps> = ({
	title,
	children,
	disabled,
	btnType = 'action',
	...props
}: ComponentProps) => {
	const colorSetup = (type: ButtonType) => {
		switch (type) {
			case 'action': {
				return 'bg-sky-500 hover:bg-sky-600';
			}
			case 'cancel': {
				return 'bg-red-500 hover:bg-red-600';
			}
			case 'secondary': {
				return 'text-sky-600 outline-1 outline hover:outline-sky-500 outline-sky-600 bg-sky hover:bg-sky-500 hover:text-neutral-50';
			}
			default: {
				return 'bg-sky-500 hover:bg-sky-600';
			}
		}
	};

	return (
		<button
			{...props}
			className={twMerge(
				'w-full rounded-md p-2 text-neutral-50 shadow-sm shadow-slate-200',
				'disabled:cursor-not-allowed disabled:text-gray-200',
				colorSetup(btnType),
				props.className
			)}
			disabled={disabled}
		>
			{children ? (
				<div className="text-base ">{children}</div>
			) : (
				<span className="text-base">{title}</span>
			)}
		</button>
	);
};

export default Button;
